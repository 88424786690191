.grid-task-item-block {
    /* border: solid 1px #999999; */
    text-align: center;
    color: #999999;
    font-size: var(--adm-font-size-9);
    padding-top: 2vh;
  }

  .grid-task-item-text-block {
    text-align: center;
    font-size: var(--adm-font-size-9);
    padding-top: 1vh;
    padding-bottom: 2vh;


  }

.grid-task-center{
    margin-top: 0;
    height: 100%;
    overflow-y: scroll;
}

/* .grid-task-center-header{
    padding: 0vh;
} */

.grid-task-center-content{
    border-radius: 10px;
    background: #ffffff;
    margin-left: 2vh;
    margin-right: 2vh;
    margin-top: 1vh;
}

.grid-task-center-content .adm-list-body{

    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.grid-task-center-invitation{
    border-radius: 10px;
    background: #ffffff;
    margin-left: 2vh;
    margin-right: 2vh;
    margin-top: 1vh;
}

.grid-task-center-invitation .adm-list-body{
    border-radius: 10px;
}


.grid-task-center-header .adm-grid-item{
    background: #ffffff;
    border-radius: 10px;
}

.content-link-text{
    /* border: solid 1px #999999; */
    text-decoration: none;
    color: #000000;
    padding-right: 35vh;
    padding-top: 1.6vh;
    padding-bottom: 1.6vh;
}

.empty-show{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 68vh;
}

.loading-parent{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 68vh;
}