.app {
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
  
  .top {
    flex: 0;
    position: fixed;
    width: 100%;
    z-index: 999;
    height: 6vh;
    border-bottom: solid 1px var(--adm-color-border);
    background-color:whitesmoke;
  }
  
  .body {
    /* margin-top:6vh; */
    flex: 1;
    display:inline-block;
    margin-bottom: 6vh;
    /*justify-content: center;
    align-items: center; */
  }
  
  .bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    border-top: solid 1px var(--adm-color-border);
    background-color:white;
  }


  /* .am-tabs-tab-bar-wrap {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 999;
  } */

