
.sysgpt .adm-collapse-panel-content .adm-list-item-content-main{
  flex: auto;
  padding: 0;
}

.sysgpt .adm-form .adm-list-item-content-main{
  flex: auto;
  padding: 12px 0;
}

.sysgpt .adm-list{
  --border-inner: solid 0px;
}