.grid-center-header .grid-item-block {
    /* border: solid 1px #999999; */
    /* background: var(--adm-color-box); */
    text-align: center;
    /* color: #999999; */
    height: 100%;
    /* border: 1px solid black; */

}


.grid-center{
    margin-top: 0;
}

.grid-center-header{
    margin-top: 0vh;
    padding-top: 4vh;
    padding-bottom: 4vh;
    background: #ffffff;
    display: flex;
    justify-content: center; /* 主轴居中 */
    align-items: center; /* 交叉轴居中 */
    
}


.grid-center-header .grid-item-block-avatar {
    /* border: solid 1px #999999; */
    /* background: var(--adm-color-box); */
    text-align: center;
    /* color: #999999; */
    height: 100%;
    /* border: 1px solid black; */

}

.grid-center-header .grid-item-block-nickname {
    height: 100%;
    /* border: 1px solid black; */
    text-align: left;
    padding-left: 5px;
    /* padding-top: 5px; */

}

.grid-center-content{
    /* margin-top: 1vh; */
     /* border: solid 1px #999999; */
    margin-left: 0.4vh;
    margin-right: 0.4vh;
    /* background: #ffffff; */
    border-radius: 10px;
}


.grid-center-content .adm-list-body{
     /* border: solid 1px #999999; */
     border-radius: 10px;
}

.grid-center-header .adm-grid-item{
    /* background: #ffffff; */
    border-radius: 10px;

}



.content-link-text{
    /* border: solid 1px #999999; */
    text-decoration: none;
    color: #000000;
    padding-right: 35vh;
    padding-top: 1.6vh;
    padding-bottom: 1.6vh;
}



.grid-center .grid-task-item-block {
    /* border: solid 1px #999999; */
    text-align: center;
    color: #999999;
    font-size: var(--adm-font-size-9);
    padding-top: 2vh;
    /* border: 1px solid black; */
  }

  .grid-center .grid-task-item-text-block {
    text-align: center;
    font-size: var(--adm-font-size-9);
    padding-top: 1vh;
    padding-bottom: 2vh;
  }

  .grid-center .grid-task-item-text-block-time {
    text-align: left;
    font-size: var(--adm-font-size-9);
    padding-top: 1.5vh;
    padding-bottom: 1.5vh;
    padding-left: 2vh;
  }

  .grid-center .grid-task-item-text-block-pay {
    text-align: center;
    font-size: var(--adm-font-size-9);
    padding-top: 1.5vh;
    padding-bottom: 1.5vh;
  }

.grid-center .grid-task-center-header{
    padding: 0vh 2vh 1vh 2vh;
}



.grid-center .adm-list-item-content-prefix {
    width: var(--prefix-width);
    flex: none;
    padding-right: var(--prefix-padding-right);
    padding-top: 0.2rem;
}