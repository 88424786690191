.app {
  /* height: 100dvh; */
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.top {
  flex: 0;
  position: fixed;
  width: 100%;
  z-index: 999;
  height: 3.3em;
  border-bottom: solid 1px var(--adm-color-border);
  background-color:whitesmoke;
}

.loading-parent{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}