.loading-parent{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.empty-show{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.user-asst .adm-list-item-description{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
}