.loading-parent{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}


.square-asst .adm-list-item-description{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  /* -webkit-line-clamp: 4; */
  -webkit-line-clamp: 3;
  height: auto;
  color: var(--adm-color-weak);
  font-size: var(--adm-font-size-main);
}

.square-asst .square-tag {
  display: inline-block;
 
  
}

.square-asst .tag-left {
  margin-left: 10px;

  /* padding-left: 10px;  */
}

.square-asst .adm-tabs-content {
  height: 85%;
  position: fixed;
  overflow: auto;
}

.tag-seg .adm-tag{
  --border-radius: var(--adm-tag-border-radius, 2px);
  padding: 0;
  font-size: 12pt;
  line-height: 1;
  font-weight: normal;
  background: #ffffff;
  color: #dde5ee;
  display: inline-block;
  white-space: nowrap;
  border-radius: 0;
  border: 1px solid #ffffff;
  box-sizing: border-box;
}

.square-asst{
  margin-left: 2vh;
  margin-right: 2vh;
  margin-top: 1vh;
}
.square-asst .adm-image {
  --width: 50px;
  width: 50px;
  --height: 50px;
  height: 50px;
  border-radius: 30px;
  display: block;
  overflow: hidden;
  /* margin-bottom: 70px; */
  margin-bottom: 30px;
}

.square-asst .adm-list-item{
  /* border: solid 1px #999999; */
  display: block;
  padding-left: var(--padding-left);
  position: relative;
  /* background-color: var(--adm-color-background); */
  line-height: 2.0;
  margin-bottom: 1vh;
  /* margin-top: 1vh; */
  border-radius: 10px;
  /* background: #f5f5f5; */
}

.square-asst .adm-list-body {
  /* border: solid 1px #999999; */
  border-top: var(--border-top);
  border-bottom: var(--border-bottom);
  background: #f5f5f5;
}


.square-asst .adm-list {
  --header-font-size: var(--adm-font-size-7);
  --prefix-width: 'auto';
  --prefix-padding-right: 12px;
  --align-items: center;
  --active-background-color: var(--adm-color-border);
  --border-inner: 0;
  --border-top: 0;
  --border-bottom: 0;
  --padding-left: 12px;
  --padding-right: 12px;
  --font-size: var(--adm-font-size-9);
  --extra-max-width: 70%;
}