.app {
  /* height: 100dvh; */
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.top {
  flex: 0;
  position: fixed;
  width: 100%;
  z-index: 999;
  height: 3.3em;
  border-bottom: solid 1px var(--adm-color-border);
  background-color:whitesmoke;
}

.loading-parent{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}


.Message-main table {
  border-collapse: collapse; /* 合并单元格边框 */
  padding: 8px;
  width: 100%;
}

.Message-main td, th {
	border: 1px solid #ddd;
	padding: 8px;
	text-align: center;
}
.Message-main th {
	background-color: #f2f2f2;
}
.Message-main tr:nth-child(even) td {
	background-color: #f2f2f2;
}
.Message-main tr:hover td {
	background-color: #ddd;
}